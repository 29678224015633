@import 'main';
@import 'listing';
@import 'drawer';
@import 'person-detail';
@import 'search-bar';
@import 'toggle-detail';

app-main {
  //styling is showing up in storybook
  main {
    h1 {
      font-weight: 600;
      font-size: 28px;
    }
    h2 {
      font-weight: 500;
      font-size: 24px;
    }
    h3 {
      font-weight: 600;
      font-size: 18px;
    }
  }
}

app-main > main {
  padding-top: 20px;
  overflow: hidden;
}
