@import 'variables';
//@imported in styles.scss
.mat-drawer-container {
  &:not(.opt-in *) {
    color: $dark-text; /* override angular default :( */
  }
  display: flex;
  background-color: transparent;
  overflow: visible !important;
  @include media-breakpoint-down(sm) {
    margin-left: -20px;
    margin-right: -5px;
  }
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-drawer:not(.mat-drawer-over) {
  border-right: 0;
  background-color: transparent;
}

mat-drawer-content {
  flex: 1 1 auto;
  overflow: visible !important;
  display: flex !important;
  min-height: calc(100vh - 176px);
  main {
    flex-grow: 1;
    padding-left: 20px;
  }
}
