@import 'variables';

article#listing {
  > header {
    display: flex;
    margin-top: -20px;
    padding: 15px 0;
    position: relative;
    z-index: 2;
    min-height: 81px;
    @include media-breakpoint-down(xs) {
      flex-wrap: wrap;
    }
    h1 {
      flex: 0 0 calc(185px + 25px);
      margin: 0 0 0 0;
      padding: 5px 0 0 15px;
      @include media-breakpoint-down(xs) {
        flex: 100%;
      }
    }
    &:before {
      content: '';
      position: absolute;
      background-color: $header-gray;
      top: 0;
      bottom: 0;
      left: -40vw;
      right: -40vw;
      z-index: -1;
      box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
    }
  }

  mat-drawer-content > main {
    min-height: 600px;
    > .selector {
      display: block;
      float: right;
      margin-left: 15px;
    }
  }

  mat-drawer > .mat-drawer-inner-container > aside {
    width: 185px;
    /* background-color: $light-gray; */
    font-size: 13px;

    > section {
      padding: 0 16px 8px;
      + section > h2 {
        border-top: 1px solid $line-gray-table;
      }
      h2 {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        margin: 0 -16px 9px;
        padding: 11px 16px 9px;
        border-bottom: 1px solid $line-gray-table;
      }
      nav {
        margin: 0 -16px;
      }
      ul {
        position: relative;
        list-style: none;
        font-size: 13px;
        padding: 0;
        margin: 0;
        > li {
          margin: 7px 0;
          padding: 0;
          .mat-radio-label-content {
            font-size: 13px;
          }
        }
      }
      .mat-accordion {
        display: block;
        margin: 0 -16px;
      }
      .mat-expansion-panel-header {
        height: auto;
        padding: 12px;
        .mat-content {
          flex-direction: column;
        }
        .mat-expansion-indicator {
          align-self: start;
          position: relative;
          top: -3px;
          &::after {
            position: absolute;
            right: 0;
            top: 7px;
          }
        }
      }
      .mat-expansion-panel-header-title {
        @include dark-color;
      }
      .mat-expansion-panel-header-description {
        margin-right: -1px;
      }
      .mat-expansion-panel-header.mat-expanded {
        .mat-expansion-panel-header-title {
          padding: 2px 0;
        }
        .mat-expansion-indicator {
          align-self: end;
          &::after {
            left: 0;
          }
        }
        .mat-expansion-panel-header-description {
          display: none;
        }
      }
      .mat-expansion-panel-body {
        padding: 0 12px 6px;
      }
    }
  }
}
