.toggle-detail-component {
  .mat-drawer-container {
    top: -20px; //app-main > main have a pad top 20px so this sets alignment
  }

  mat-drawer {
    min-width: 186px;
    .mat-nav-list {
      padding-top: 15px;
      .mat-list-item {
        height: 38px;
      }
    }
    nav + div {
      padding: 20px;
    }
  }

  mat-drawer-content {
    > main {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }
}
