@import 'variables';

.search-bar {
  mat-form-field {
    width: calc(100% - 50px);
  }
  input {
    font-size: 16px !important;
  }
  .mat-button {
    @include dark-bg;
    color: white;
    min-width: 48px;
    height: 48px;
    padding: 0;
    position: relative;
    top: -1px;
    border-radius: 0;
  }
  .mat-form-field-infix {
    border: none;
    padding: 15px;
    background-color: white;
    box-shadow: inset 0px 1px 8px 0px rgba(0, 0, 0, 0.2);
  }
  .mat-form-field-appearance-standard .mat-form-field-flex {
    padding-top: 0;
  }
  .mat-form-field-appearance-standard .mat-form-field-underline {
    display: none;
  }
  .mat-form-field-suffix {
    right: 37px;
    top: 13px !important;
    width: 0;
    cursor: pointer;
    .mat-icon {
      background-color: white;
    }
  }
}

#listing {
  .search-bar {
    width: 100%;
    .mat-form-field-infix {
      border: 1px solid $underline-gray;
      box-shadow: none;
    }
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
    .mat-button {
      width: 50px;
      height: 50px;
    }
  }
}
